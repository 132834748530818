@import '../../App.scss';

.LoginBottomRight {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

	p {
		font-family: $inter;
		font-size: 16px;
        font-weight: 500;
		color: #1C2E3B;
		letter-spacing: 0;
		line-height: 24px;
        text-align: center;
        margin-bottom: 18px;
	}

	.create-button {
		display:block;
		border-radius:4px;
		font-family: $inter;
		font-size: 16px;
		line-height:18px;
		color: #1C2E3B;
		letter-spacing: 0;
		text-align: center;
		background:#BCD2E0;
		padding:16px 8px;
		text-decoration: none;
		display: flex;
    	align-items: center;
    	justify-content: center;
        margin-bottom: 32px;
        cursor: pointer;
        transition: color 0.35s ease, background-color 0.35s ease;

    	svg {
    		margin-right:8px;

            .fill-path {
                transition: fill 0.35s ease;
            }
    	}

        &:hover {
            color: #BCD2E0;
            background-color: #1C2E3B;

            svg .fill-path {
                fill: #BCD2E0;
            }
        }
	}

    .FooterLinks {
        margin-top: auto;
    }
}

@media screen and (min-width: 481px) {
    .LoginBottomRight {
        .create-button {
            margin-bottom: 70px;
        }
    }
}