@import "../../App.scss";

.UpcomingProject {
    background-color: $darkblue;
    padding: 8px 8px 21px;
    border-radius: 4px;

    .project-image {
        border-radius: 4px;
        margin-bottom: 16px;
        overflow: hidden;
        position: relative;

        &::after {
            content: "";
            display: block;
            padding-bottom: 62.8253%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    .content-wrapper {
        .tag-wrapper {
            background-color: $gold;
            padding: 4px 8px;
            color: $darkblue;
            margin-bottom: 18px;
            display: inline-block;
            border-radius: 4px;
        }

        .heading {
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0.89px;
            color: $white;
            margin-bottom: 12px;
        }

        .register-link {
            font-size: 14px;
            line-height: 16px;
            color: #ABBECC;
            cursor:  pointer;
            transition: color 0.35s ease;
            text-decoration: none;

            &:hover {
                color: $gold;
            }
        }
    }
}