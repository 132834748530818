@import "../../App.scss";

@keyframes slideRight {
    from {right: 100%;}
    to {right: -50%;}
}

.LoadingCover {
    height: 100vh;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 56px auto 83px;

    .preview-cover {
        width: 100%;
        border-radius: 4px;
        background-color: #2A4152;
        position: relative;
        overflow: hidden;

        &::after {
            content: "";
            right: 100%;
            display: block;
            position: absolute;
            height: 100%;
            width: 5%;
            background-color: darken(#2A4152, 1%);
            animation: slideRight 1.2s linear infinite;
            box-shadow: 0 -20px lighten(darken(#2A4152, 1%), 2%);
        }
    }

    .left-side-bar-loader {
        background-color: $darkblue;
        display: flex;
        flex-direction: column;

        .preview-cover {
            height: 40px;
            margin-bottom: 8px;

            &.sub-item {
                height: 24px;
            }
    
            &.small-item {
                height: 20px;
            }
    
            &.auto-margin {
                margin-bottom: auto;
            }
    
            &.big-space {
                margin-bottom: 32px;
            }
        }
    }

    .content-loader {
        background-color: $lightblue;
    }

    .right-side-bar-loader {
        background-color: #CED9E0;

        .preview-cover {
            height: 300px;
            background-color: #F7F9FA;

            &::after {
                background-color: darken(#F7F9FA, 5%);
            }
        }
        
        >.preview-cover:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}

@media screen and (min-width: 900px) {
    .LoadingCover {
        height: 100vh;
        grid-template-rows: auto;
        grid-template-columns: 204px auto;

        .left-side-bar-loader {
            height: 100vh;
            padding: 135px 12px 12px;

            .preview-cover {
                &.bottom-space {
                    margin-bottom: 118px;
                }
            }
        }

        .right-side-bar-loader {
            display: none;
        }
    }
}

@media screen and (min-width: 1025px) {
    .LoadingCover {
        grid-template-columns: 204px 8fr 3fr ;

        .right-side-bar-loader {
            display: block;
            max-height: 100vh;
            padding: 98px 16px 0;
            overflow-y: hidden;
            max-height: 100vh;
        }
    }
}

@media screen and (min-width: 1201px) {
    .LoadingCover {
        grid-template-columns: 204px auto 340px;

        .right-side-bar-loader {
            padding: 104px 32px 0;
        }
    }
}