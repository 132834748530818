@import '../../App.scss';

.LeftSideBarIcon {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px 12px;
    margin-bottom: 8px;
    font-family: $inter;
    font-size: 12px;
    font-weight: 500;
    color: #9AABB8;
    text-decoration: none;
    letter-spacing: 0;
    line-height: 1;
    border-radius: 4px;
    transition: color 0.35s ease, background-color 0.35s ease;

    svg {
        margin-right: 12px;

        .fill-path {
            transition: fill 0.35s ease;
        }
    }

    &.active,&:hover {
        color: #FFFFFF;
        background-color: #2A4152;

        svg {
            .fill-path {
                fill: #FFFFFF;
            }
        }
    }

    &.mobile {
        flex-direction: column;
        padding: 0;
        margin-bottom: 0;
        font-size: 10px;
        border-radius: 0;
        background-color: transparent;

        svg {
            margin-right: 0;
            margin-bottom: 4px;
        }
    }
}