.FormSingleCheckbox {
    display: flex;

    .input-wrapper {
        position: relative;
        margin-right: 16px;

        .box {
            width: 24px;
            height: 24px;
            position: relative;
            background-color: #EBEEF0;
            border-radius: 4px;

            .check {
                position: absolute;
                height: 11px;
                width: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
            }

            >input {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                position: absolute;
                background-color: #EBEEF0;
                border-color: #EBEEF0;
                opacity: 0;
                z-index: 10;
                -webkit-appearance: none;
            }   
        }
    }

    .select-heading {
        font-size: 14px;
        font-weight: 600;
        color: #1C2E3B;
        line-height: 1;
        margin-bottom: 8px;
        display: block;
    }

    .select-description {
        font-size: 14px;
        font-weight: 600;
        color: #576F80;
        line-height: 20px;
        display: block;
    }

    &.checked {
        .box {
            .check {
                opacity: 1;
            }
        }
    }
}