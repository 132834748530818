@import "../../App.scss";

.MobileHamburger {
    .hamburger-button-container {
        height: 14px;
        width: 24px;
        position: relative;

        >.line {
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $darkblue;

            &:nth-child(1) {
                top: 0;
                transition: transform 0.35s ease, top 0.35s ease;
            }

            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
                transition: opacity 0.35s ease;
            }

            &:nth-child(3) {
                top: 100%;
                transform: translateY(-100%);
                transition: transform 0.35s ease, top 0.35s ease;
            }
        }
    }

    .hamburger-content-container {
        position: fixed;
        z-index: 1001;
        top: 56px;
        width: 100%;
        background-color: #FFFFFF;
        left: 0;
        pointer-events: none;
        overflow-y: hidden;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 16px;
        padding-right: 16px;
        transition: height 0.35s ease,
            padding 0.35s ease;
    }

    &.open {
        .hamburger-button-container {
            >.line {
                &:nth-child(1) {
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                }
    
                &:nth-child(2) {
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: 0;
                }
    
                &:nth-child(3) {
                    top: 50%;
                    transform: translateY(-50%) rotate(-45deg);
                }
            }
        }

        .hamburger-content-container {
            height: calc(100vh - 83px - 56px);
            overflow-y: scroll;
            padding-top: 40px;
            padding-bottom: 16px;
            pointer-events: auto;
        }
    }
}

.MobileHamburgerItem {
    display: block;
    text-decoration: none;
    color: $darkblue;
    font-size: 28px;
    font-family: $inter;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
    transition: color 0.35s ease;

    &:hover {
        color: $gold;
    }
}

@media screen and (min-width: 901px) {
    .MobileHamburger {
        display: none;
    }
}