@import '../../App.scss';

.DashboardEarnings {
    display: grid;
    
    grid-template-rows: 48px auto;
    background-color: #F7F9FA;
    border-radius: 4px;

    .earnings-container {
        grid-column: 1 / 6;
        grid-row: 1;
        display: flex;
        align-items: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        justify-content: space-between;
        background-color: #FFFFFF;
        padding-left: 24px;
        padding-right: 24px;

        .earnings {

        }

        .summery {
            align-items: center;
            text-align: right;
            display: flex;
            align-items: center;
            a {
                font-family: $inter;
                font-weight:bold;
                font-size: 12px;
                color: #1C2E3B;
                letter-spacing: 0;
                line-height: 14px;
                text-decoration: none;
                transition: color 0.35s ease;
                display: inline-flex;
                align-items: center;

                &:hover {
                    color: #9AABB8;
                }
            }

            .arrow {
                width: 7px;
                height: 12px;
                margin-right: 8px;
            }
        }
    }

    .sales-rewards  {
        grid-column: 1 / 6;
        grid-row: 2;
        padding: 32px 24px;
        padding-right: 0;
        margin-right: 32px;
        display: grid;
        grid-column-gap: 30px;
        row-gap: 20px;
        grid-template-columns: 24px auto;
        align-items: center;
        position: relative;

        .bg-arrow {
            position: absolute;
            height: 100%;
            width: auto;
            display: none;
            right: 0;
            top: 0;
        }

        .label {
            font-family: $inter;
            font-size: 14px;
            color: #576F80;
            display: flex;
            align-items: center;
            line-height: 16px;

            .number {
                margin-right: 13px;
                font-size: 24px;
                letter-spacing: -1.32px;
                color: #1C2E3B;
                font-weight: 600;
            }
        }
    }

    .points-events {    
        padding-top: 28px;
        padding-bottom: 16px;
        text-align: right;
        padding-right: 24px;
        display: none;

        .date {
            text-align: left;
            font-size: 12px;
            line-height: 14px;
            color: #617380;
        }

        .project-name {
            text-align: left;
            font-size: 14px;
            line-height: 14px;
            color: #1C2E3B;
            font-weight: 600;
        }

        .points {
            text-align: left;
        }
    }
}

@media screen and (min-width: 641px) {
    .DashboardEarnings {
        grid-template-columns: repeat(5, 1fr);

        .sales-rewards {
            grid-column: 1 / 3;
            padding-top: 26px;
            padding-left: 24px;
            padding-bottom: 30px;
            row-gap: auto;

            .bg-arrow {
                display: block;
            }
        }

        .points-events {
            grid-column: 3 / 6;
            grid-row: 2;
            display: grid;
            grid-template-columns: auto auto auto;
            grid-template-rows: 19px 19px 19px 19px;
            align-items: center;
            justify-content: space-between;
            row-gap: 16px;
        }
    }
}