@import "../../App.scss";

.ProjectDetails {
    display: grid;
    grid-template-columns: auto;

    .ProjectDetailsContentContainer {
        min-height: 0;
        display: flex;
        flex-direction: column;
        grid-template-columns: auto;
        column-gap: 16px;

        .nav-wrapper {
            order: 0;
            flex: 0 1 auto;
            margin-bottom: 20px;
            padding-top: 20px;
        }
        
        .content-wrapper {
            max-height: auto;
            display: flex;
            flex-direction: column;
            order: 2;
            margin-bottom: 0;

            .inner-wrapper {
                flex: 1 1 auto;
                background-color: #FFFFFF;
                padding: 8px;
                padding-bottom: 30px;
                border-radius: 4px;

                .project-image {
                    margin-bottom: 32px;
                    border-radius: 4px;
                    max-width: 100%;
                    height: auto;
                    display: block;
                    width: 100%;
                }

                .description-container {
                    font-family: $inter;
                    font-size: 16px;
                    line-height: 24px;
                    color: #576F80;
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }
        }

        .project-info-wrapper {
            max-height: auto;
            padding-top: 16px;
            order: 1;

            .project-info-container {
                border-radius: 4px;
                padding: 40px 16px 16px;
                background-color: #FFFFFF;
                margin-bottom: 16px;

                .project-logo {
                    height: auto; 
                    width: auto;
                    display: block;
                    margin-bottom: 27px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .info-group {
                    margin-bottom: 40px;

                    .field {
                        padding-top: 6px;
                        padding-bottom: 12px;

                        label {
                            display: block;
                            font-family: $inter;
                            font-size: 12px;
                            line-height: 28px;
                            color: #859CAB;
                        }

                        span {
                            font-family: $inter;
                            display: block;
                            color: #1C2E3B;
                            font-size: 14px;
                            font-weight: 500;
                        }

                        a {
                            display: flex;
                            color: #1C2E3B;
                            font-size: 14px;
                            font-weight: 500;
                            font-family: $inter;
                            text-decoration: none;
                            align-items: center;

                            svg {
                                margin-left: auto;
                                position: relative;
                                top: -5px;
                                min-width: 22px;
                            }
                        }
                    }

                    >.field:not(:last-child) {
                        border-bottom: 1px solid #EBEEF0;
                    }
                }

                .link-groups {
                    >.ProjectDetailsButton:not(:last-child) {
                        margin-bottom: 16px;
                    }
                }
            }
        }

        .incentives-container {
            order: 3;
            
            .title {
                font-family: $inter;
                font-weight: 600;
                color: #1C2E3B;
                font-size: 14px;
                line-height: 1;
                background-color: #FFFFFF;
                padding: 12px 16px 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            .item-container {
                >.ProjectDetailsIncentive:not(:last-child) {
                    margin-bottom: 1px;
                }
            }

            .load-more {
                height: 42px;
                background-color: #FFFFFF;
                font-family: $inter;
                color: #1C2E3B;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                cursor: pointer;

                &.disabled {
                    opacity: 0.5;
                    cursor: default;
                }
            }
        }
    }
}

.ProjectDetailsButton {
    display: flex;
    text-decoration: none;
    height: 56px;
    background-color: #BCD2E0;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-family: $inter;
    font-size: 16px;
    color: #1C2E3B;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 4px;
    transition: color 0.35s ease, background-color 0.35s ease;

    svg {
        margin-right: auto;

        * {
            transition: fill 0.35s ease;
        }
    }

    span {
        margin-right: auto;
    }

    &:hover {
        background-color: $darkblue;
        color: $white;

        svg {
            * {
                fill: $white;
            }
        }
    }
}

.ProjectDetailsIncentive {
    display: flex;
    padding: 12px 16px;
    background-color: #F7F9FA;
    align-items: center;
    justify-content: space-between;
    
    span {
        color: #617380;
        font-size: 12px;
        line-height: 14px;
    }

    .text {
        padding-right: 8px;
    }

    .points {
        padding-left: 8px;
    }
}

@media screen and (min-width: 900px) {
    .ProjectDetails {
        grid-template-columns: 204px auto;

        .ProjectDetailsContentContainer {
            min-height: 100%;
            grid-template-columns: auto 307px;
            column-gap: 32px;
            display: grid;

            .nav-wrapper {
                padding-top: 0;
            }

            .content-wrapper {
                //max-height: calc(100vh - 40px - 40px);
                //overflow-y: scroll;
                order: 0;

                .inner-wrapper {
                    .description-container {
                        padding-left: 24px;
                        padding-right: 24px;
                    }
                }
            }

            .project-info-wrapper {
                max-height: calc(100vh - 40px - 40px);
                overflow-y: auto;
                padding-top: 40px;
                order: 0;

                .project-info-container {
                    margin-bottom: 40px;
                }
            }

            .incentives-container {
                order: 0;
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .ProjectDetails {
        .ProjectDetailsContentContainer {
            .project-info-wrapper {
                max-height: none;
            }
        }
    }
}