@import '../../App.scss';

.LeftSidebarLight {
	background:$lightblue;
    padding: 32px 16px;
    display: flex;
    flex-direction: column;

	h1 {
		font-family: $helveticalight;
		font-size: 24px;
		color: #1C2E3B;
		letter-spacing: 1.2px;
		line-height: 32px;
        margin-bottom: 40px;
	}
	h2 {
		font-family: $inter;
		font-size: 18px;
		color: #1C2E3B;
		letter-spacing: 0;
		line-height: 28px;
		font-weight:300;
	}
	p {
		font-family: $inter;
		font-size: 14px;
		color: #576F80;
		letter-spacing: 0;
		line-height: 24px;
	}
}

@media screen and (min-width: 900px) {
    .LeftSidebarLight {
        padding: 172px 28px 18px 18px;
    }
}