@import '../../App.scss';

.LeftNotificationSidebar {
    background-color: #EBEEF0;
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 12px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;

    h4 {
        font-family: $inter;
        font-weight: bold;
        font-size: 12px;
        color: #1C2E3B;
        letter-spacing: 0;
        border-bottom: 1px solid #C0C7CC;
        padding-bottom: 8px;

        span {
            background: #FF1E1E;
            font-family: $inter;
            font-size: 10.45px;
            color: #FFFFFF;
            letter-spacing: -0.35px;
            text-align: center;
            line-height: 10.45px;
            padding: 3px 5px;
            border-radius: 3px;
            margin-left: 8px;
        }

        &.zero-items {
            display: none;
        }
    }

    .notification-left-box {
        padding: 16px 16px 16px 48px;
        position: relative;
        cursor: pointer;
        border-radius: 4px;
        transform: translateY(0px);
        transition: background-color 0.35s ease;

        svg {
            position: absolute;
            right: 16px;
            top: 24px;
        }

        .notification-icon {
            width: 24px;
            position: absolute;
            left: 16px;
            top: 16px;
        }

        h3 {
            margin-bottom: 8px;
            padding-right: 8px;
        }

        &.active {
            //background: #D3DBE0;
        }

        &.animate-out {
            transition: transform 1.6s ease, opacity 1.6s ease;
            transform: translateY(300px);
            opacity: 0;
            background: #D3DBE0;
        }

        &.animate-in-archive {
            background: #D3DBE0;
        }

        &:hover {
            background: #D3DBE0;
        }
    }

    .filter-notifications-type {
        margin-top: 24px;
        margin-bottom: 48px;

        span {
            cursor: pointer;
            padding: 16px;
            font-family: $inter;
            font-weight: 500;
            font-size: 14px;
            color: #455866;
            letter-spacing: 0;
            line-height: 16px;
            border-radius: 4px;
            transition: background-color 0.35s ease;

            &.active {
                background: #D3DBE0;
            }

            &:hover {
                background: #D3DBE0;
            }
        }
    }
}





.circle {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    background-color: rgb(235, 207, 51);
    position: relative;
    margin: 0.6rem;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.text {
    text-align: center;
    margin: 5px;
    font-size: 14px;
}

.image {
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 50%;
    color: black;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.5rem;
}

.button-wrapper {
    text-align: center;
}

.button {
    margin: 2rem 0.4rem;
    color: #ffffff;
    padding: 0.5rem 2rem;
    font-size: 0.8rem;
    cursor: pointer;
    border: none;
    transition: background-color 0.5s ease;
    background-color: #2e81d4;
}

.button:hover {
    background-color: #042c55;
}

.bubbles-wrapper {
    //display: flex;
}

.bubbles-group {
    //display: flex;
    margin: 1rem auto;
}

@media screen and (min-width: 900px) {
    .LeftNotificationSidebar {


        .notification-left-box {

            &.active {
                background: #D3DBE0;
            }
        }
    }
}

@media screen and (min-width: 1025px) {
    .LeftNotificationSidebar {
        padding: 32px 32px 12px;
    }
}