@import "../../App.scss";

.PortfolioUpcomingProject {
    background-color: $darkblue;
    padding: 8px;
    display: flex;
    border-radius: 4px;
    margin-bottom: 24px;
    flex-direction: column;
    
    .image-wrapper {
        min-width: 100%;
        width: 100%;
        position: relative;
        border-radius: 4px;
        overflow: hidden;

        &::after {
            content: "";
            display: block;
            padding-bottom: 62.8253%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    .info-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        padding-bottom: 8px;
        padding-left: 16px;

        .tag {
            margin-bottom: 18px;
            flex: 0 1 auto;

            span {
                background-color: $gold;
                color: $darkblue;
                width: auto;
                display: inline-block;
                font-family: $inter;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                padding: 4px 8px;
                border-radius: 4px;
            }
        }

        .heading {
            font-family: $helveticalight;
            font-size: 20px;
            letter-spacing: 0.89px;
            line-height: 20px;
            color: $white;
            margin-bottom: 12px;
        }

        .register {
            margin-top: auto;
            font-family: $inter;
            font-size: 12px;
            line-height: 16px;
            color: $skyblue;
            text-decoration: none;
        }
    }
}

@media screen and (min-width: 481px) {
    .PortfolioUpcomingProject {
        flex-direction: row;

        .image-wrapper {
            min-width: calc(50% - 20px);
            width: calc(50% - 20px);
        }
    }
}

@media screen and (min-width: 900px) {
    .PortfolioUpcomingProject {
        flex-direction: column;

        .image-wrapper {
            min-width: 100%;
            width: 100%;
        }
    }
}

@media screen and (min-width: 1201px) {
    .PortfolioUpcomingProject {
        flex-direction: row;
        .image-wrapper {
            min-width: calc(50% - 20px);
            width: calc(50% - 20px);
        }
    }
}