@import '../../App.scss';

.Notifications {
    display: grid;
    grid-template-columns: auto;

	h2 {
		font-family: $helveticalight;
		font-size: 32px;
		color: #1C2E3B;
		letter-spacing: 1.6px;
		line-height: 44px;
	}
	h3 {
		font-family: $inter;
		font-size: 16px;
		color: #1C2E3B;
		letter-spacing: 0;
	}
	.date {
		font-family: $inter;
		font-size: 12px;
		color: #455866;
		letter-spacing: -0.4px;
		line-height: 14px;
	}

	.notifications-content {
        position: fixed;
        background-color: $white;
        height: calc(100vh - 83px - 56px);
        top: 56px;
        left: 0;
        overflow-y: auto;
        padding: 36px 16px 48px;
        width: 100%;

        .close-link {
            display: block;
            margin-bottom: 54px;
            font-family: $inter;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: $darkblue;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                margin-right: 8px;
            }
        }

		h1 {
			font-family: $helveticalight;
			font-size: 32px;
			color: #1C2E3B;
			letter-spacing: 1.6px;
			line-height: 44px;
            margin-bottom:24px;
            font-weight:300;
            position: relative;
		}
        .notification-icon {
            position: absolute;
            top:4px;
            left:-64px;
            width:40px;
        }
        p.date {
            margin-bottom:32px;
        }
		p.copy {
			font-family: $inter;
			font-size: 16px;
			color: #576F80;
			letter-spacing: 0;
			line-height: 24px;
            max-width:500px;
            margin-bottom: 24px;

            a {
                color: $darkblue;
                font-weight: 700;
                text-decoration: none;
                transition: color 0.32s ease;
            }

            a:hover {
                color: #9aabb8;
            }
		}
	}
}

@media screen and (min-width: 900px) {
    .Notifications {
        grid-template-columns: 204px 320px auto;

        .notifications-content {
            position: static;
            height: 100vh;
            

            .close-link {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 1025px) {
    .Notifications {
        grid-template-columns: 204px 369px auto;

        .notifications-content {
            padding: 64px 64px 64px 112px;
        }
    }
}