@import '../../App.scss';

.LeftUserSidebar {
    display: flex;
    background-color: #EBEEF0;
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    flex-direction: column;
    min-height: calc(100vh - 83px - 53px);

    .links {
        margin-bottom: auto;
        margin-top: 24px;
    }

    .logout {
        position: relative;
        padding-top: 22px;
        padding-bottom: 22px;
        color: #1C2E3B;
        font-size: 16px;
        line-height: 20px;
        background-color: #FFFFFF;
        font-weight: 600;
        display: flex;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;

        img {
            position: absolute;
            width: 24px;
            height: 24px;
            left: 14px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

@media screen and (min-width: 901px) {
    .LeftUserSidebar {
        height: 100vh;
        padding-top: 32px;
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 12px;

        .links {
            margin-top: 0;
        }
    }
}