@import "../../App.scss";

.ProfileSecurity {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;

    .lock {
        margin-bottom: 42px;
        height: 72px;
        align-self: flex-start;
    }

    .form-wrapper {
        margin-bottom: auto;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 40px;
    }

    .required-fields {
        margin-top: 12px;
        margin-bottom: 32px;
        font-family: $inter;
        color: #66727A;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media screen and (min-width: 900px) {
    .ProfileSecurity {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .form-wrapper {
            padding: 0;
            max-width: 340px;
        }

        .required-fields {
            text-align: left;
        }
    }
}