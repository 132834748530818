@import '../../App.scss';

.FAQ {
    display: grid;
    grid-template-columns: auto;

    h1 {
        margin-bottom: 40px;
    }

    .faq-wrapper {
        >*:not(:last-child) {
            margin-bottom: 1px;
        }

        >*:first-child {
            .FATitle {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }

        >*:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

@media screen and (min-width: 900px) {
    .FAQ {
        grid-template-columns: 204px auto;
    }
}