@import '../../App.scss';

.LoginLeft {
	/*background-image:url('img/login-left-background.jpg');*/
	background-size:cover;
	height:100vh;
	padding-top:172px;
    width: 100%;
    display: none;
    align-items: center;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    
    h1 {
    	font-family: $helveticalight;
		font-size: 38px;
        font-weight: 200;
		color: #FFFFFF;
		letter-spacing: 1.6px;
		line-height: 42px;
        max-width: 488px;
        margin-bottom: 56px;
    }

    h2 {
		font-family: $inter;
		font-size: 18px;
		color: #FFFFFF;
		letter-spacing: 0;
		line-height: 28px;
        max-width: 434px;
        margin-bottom: 12px;
    }

    .body-copy {
	    font-family: $inter;
		font-size: 16px;
		color: #9AABB8;
		letter-spacing: 0;
		line-height: 28px;
        max-width: 434px;	
    }
}

@media screen and (min-width: 900px) {
    .LoginLeft {
        display: flex;
    }
}