@import '../../App.scss';

.Dashboard {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;

    .main-content-wrapper {
        display: grid;
        height: auto;
        padding-top: 48px;
        padding-bottom: 32px;
        padding-left: 16px;
        padding-right: 16px;
        grid-row-gap: 32px;
        grid-column-gap: 32px;
        grid-template-columns: auto;
        grid-row-gap: 0;
        grid-column-gap: 0;
        background-color: #EBEEF0;
        grid-template-columns: auto;
    }

    .whats-new-button {
        display: none;
        position: absolute;
        font-size: 12px;
        font-family: $inter;
        color: $darkblue;
        line-height: 14px;
        font-weight: 600;
        padding: 6px 8px;
        background-color: $gold;
        border-radius: 4px;
        right: 32px;
        top: 16px;
        z-index: 10000;
    }

    .outer-wrapper {
        background-color: #EBEEF0;
    }
}

@media screen and (min-width: 481px) {
    .Dashboard {
    }
}

@media screen and (min-width: 641px) {
    .Dashboard {
        .main-content-wrapper {
            padding-top: 48px;
            padding-bottom: 32px;
            padding-left: 32px;
            padding-right: 32px;
            grid-row-gap: 32px;
            grid-column-gap: 32px;
            grid-template-columns: auto;
        }
    }
}

@media screen and (min-width: 768px) {
    .Dashboard {
        .main-content-wrapper {
            height: auto;
            grid-template-columns: repeat(5, 1fr);

            >* {
                &:nth-child(1) {
                    grid-column: 1 / 6;
                    grid-row: 1;
                }
    
                &:nth-child(2) {
                    grid-column: 1 / 3;
                    grid-row: 2;
                }
    
                &:nth-child(3) {
                    grid-column: 3 / 6;
                    grid-row: 2;
                }
    
                &:nth-child(4) {
                    grid-column: 1 / 6;
                    grid-row: 3;
                }
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .Dashboard {
        grid-template-columns: 204px auto;
        min-height: 100vh;
        height: 100vh;

        .main-content-wrapper {
            height: 100vh;
            //overflow-y: scroll;
            padding-top: 48px;

            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .whats-new-button {
            display: none;
        }
    }
}

@media screen and (min-width: 1025px) {
    .Dashboard {
        grid-template-columns: 204px 8fr 3fr;

        .whats-new-button {
            display: none;
        }

        .main-content-wrapper {
            padding-top: 56px;
        }
    }
}

@media screen and (min-width: 1201px) {
    .Dashboard {
        grid-template-columns: 204px auto 340px;
    }
}

@media screen and (max-height: 932px) and (min-width: 900px) {
    .Dashboard {
        height: auto;
        overflow-y: scroll;

        .main-content-wrapper {
            height: auto;
        }
    }
}