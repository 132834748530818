@import "../../App.scss";

.FormReqStar {
    display: inline-block;
    color: #ED1F1F;
    font-family: $inter;
    font-size: 12px;
    bottom: 2px;
    line-height: 1;
    position: relative;
}