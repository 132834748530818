@import '../../App.scss';

.Incentives {
    display: grid;
    grid-template-columns: auto;

    .content-container {
        background-color: #EBEEF0;
        height: auto;
        padding: 16px;
        min-height: calc(100vh - 83px - 53px);

        display: flex;
        flex-direction: column;

        .top-wrapper {
            display: grid;
            align-items: center;
            margin-bottom: 24px;
            grid-template-columns: auto;

            .heading {
                font-family: "Helvetica Neue LT W05_45 Light";
                font-size: 32px;
                letter-spacing: 1.6px;
                line-height: 44px;
                margin: 0;
                font-weight: regular;
                margin-right: auto;
                color: #1C2E3B;
            }

            .select-placeholder {
                width: 275px;
                height: 56px;
                background-color: #F7F9FA;
            }

        }

        .incentive-container {
            display: grid;
            grid-template-columns: auto;
            row-gap: 16px;
        }

        .no-incentives {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex: 1 1 auto;
            
            img {
                margin-bottom: 27px;
                width: 64px;
                height: auto;
            }

            .text {
                font-family: $inter;
                font-size: 18px;
                line-height: 28px;
                color: $darkblue;
                font-weight: 500;
                max-width: 369px;
            }
        }
    }
}

@media screen and (min-width: 641px) {
    .Incentives {
        .content-container {
            .top-wrapper {
                grid-template-columns: 2fr 1fr 1fr;
            }
            .incentive-container {
                row-gap: 24px;
                column-gap: 24px;
                grid-template-columns: 1fr 1fr;

            }

            .no-incentives {
                grid-column: 1 / 3;
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .Incentives {
        display: grid;
        grid-template-columns: 204px auto;

        .content-container {
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 32px;
            padding-right: 32px;
            height: 100vh;
            overflow-y: auto;
            min-height: 0;

            .incentive-container {
            }
        }
    }
}

@media screen and (min-width: 1025px) {
    .Incentives {
        .content-container {
            

            .incentive-container {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            .no-incentives {
                grid-column: 1 / 5;
            }
        }
    }
}