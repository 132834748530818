@import "../../App.scss";

.ArrowLink {
    display: flex;
    align-items: center;
    font-family: $inter;
    font-weight: bold;
    font-size: 12px;
    color: $white;
    letter-spacing: 0;
    line-height: 14px;
    text-decoration: none;
    transition: color 0.35s ease;
    text-decoration: none;

    img {
        margin-right: 8px;
        transform: rotateX(0);
        transition: transform 0.8s cubic-bezier(0.56, 0, 0.24, 1);
    }

    &:hover {
        color: #9AABB8;

        img {
            transform: rotateX(180deg);
        }
    }

    &.black {
        color: $black;

        &:hover {
            color: #576F80;
        }
    }
}