@import "../../App.scss";

.DashboardPointsSummary {
    display: grid;
    grid-template-columns: auto;

    .ContentLightBlueWrapper {
        width: 100%;
        overflow-x: auto;
    }

    .points-wrapper {
        h2 {
            font-family: $inter;
            font-size: 32px;
            letter-spacing: 1.6px;
            line-height: 44px;
            color: #1C2E3B;
            font-weight: 200;
            margin-bottom: 32px;
        }
    }
}

@media screen and (min-width: 900px) {
    .DashboardPointsSummary {
        grid-template-columns: 204px auto;
    }
}