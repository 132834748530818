@import '../../App.scss';

.LoginRight {
    min-width: 100%;
    max-width: 100%;
	background:#fff;
    padding: 32px 16px 16px;
    overflow-y: scroll;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .right-content-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    .ascent-logo {
        margin-bottom: 12px;
    }

	.tagline {
		display:block;
		font-family: $inter;
		font-weight:500;
		font-size: 12px;
		color: #A49368;
		letter-spacing: 0;
		line-height: 16px;
		margin-bottom: 60px;
	}
	img {
		max-width:100%;
	}
	.login-links {
        text-align: center;
        flex: 0 1 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        cursor: pointer;

        a {
            font-family: $inter;
            font-weight:bold;
            font-size: 12px;
            color: #1C2E3B;
            letter-spacing: 0;
            text-align: center;
            display: inline-block;
            text-decoration: none;
            cursor: pointer;
            transition: color 0.35s ease;

            &:hover {
                color: #BCD2E0;
            }
        }	
	}

    .LoginBottomRight {
        //margin-bottom: auto;
    }
}

@media screen and (min-width: 900px) {
    .LoginRight {
        min-width: 505px;
        max-width: 505px;
        height: 100vh;
        padding: 172px 92px 26px;

        .login-links {
            margin-bottom: auto;
        }
    }
}