// Global Variables
$darkblue: #1C2E3B;
$skyblue: #C1D3E0;
$lightblue: #EBEEF0;
$gold: #A49368;
$white: #FFFFFF;
$black: #000000;
$inter: 'Inter', sans-serif;
$helveticalight: 'Helvetica Neue LT W05_45 Light';
$helvetica: 'Helvetica Neue LT W05_55 Roman';
$helveticabold: 'Helvetica Neue LT W05_75 Bold';

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin: 0;
}

body {
    background-color: $darkblue;
}