@import '../../App.scss';

.DashboardNotificationBar {
	position:relative;
	.notification-bar {
		background:$gold;
		color:$darkblue;
		font-family: "Inter", sans-serif;
	    font-size: 14px;
	    letter-spacing: 0;
	    line-height: 16px;
		padding:8px 24px;
		position:fixed;
		top:56px;
		left:0px;
		width:100%;
		z-index:20;
		text-align:center;
		cursor:pointer;
	}
}


@media screen and (min-width: 900px) {
	.DashboardNotificationBar {
		.notification-bar {
			position: absolute;
			top: 0;
		}
	}
}