@import "../../App.scss";

.Incentive {
    border-radius: 4px;
    background-color: #FFFFFF;
    height: 100%;
    padding-top: 8px;
    padding-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    text-decoration: none;

    .project-image {
        margin-bottom: 16px;
        border-radius: 4px;
        position: relative;
        width: 100%;
        overflow: hidden;

        &::after {
            content: "";
            display: block;
            padding-bottom: 62.8253%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    .content-wrapper {
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        .points-container {
            padding: 4px 8px;
            display: inline-flex;
            align-items: center;
            border-radius: 4px;
            background-color: #EBEEF0;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            color: #1C2E3B;
            margin-bottom: 16px;
            
            .points-icon {
                width: 16px;
                height: 16px;
                margin-right: 6px;
            }
        }

        .heading {
            margin-bottom: 8px;
            font-size: 20px;
            line-height: 1;
            font-weight: 300;
            letter-spacing: 0.89px;
            color: #1C2E3B;
        }

        .units {
            font-size: 14px;
            color: #1C2E3B;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 6px;
        }

        .location {
            font-size: 12px;
            color: #576F80;
            margin-top: auto;
        }
    }
}