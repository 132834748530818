@import '../../App.scss';

.MobileUserProfile {
    justify-self: center;
    align-self: center;

    .image-wrapper {
        overflow: hidden;
        border-radius: 50%;
        position: relative;
        width: 35px;
        height: 35px;
        background-color: $white;
        
        .profile-picture {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    &.active {
        .image-wrapper {
            border: 2px solid #FFFFFF;
        }
    }
}