@import "../../App.scss";

.IncentiveFilter {
    width: 100%;
    height: 56px;    

    .inner-wrapper {
        position: relative;
        display: block;

        select {
            width: 100%;
            height: 56px;
            padding-left: 16px;
            padding-right: 32px;
            border-radius: 4px;
            border: none;
            font-size: 14px;
            color: $darkblue;
            font-family: $inter;
            font-weight: 600;
            background-color: #F7F9FA;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            cursor: pointer;
        }
    
        .dropdown-arrow {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}