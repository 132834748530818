@import "../../App.scss";

.FAQQuestion {
    cursor: pointer;

    .FATitle {
        background-color: #FFFFFF;
        padding: 24px 32px;
        font-family: $inter;
        font-size: 18px;
        line-height: 1;
        color: #1C2E3B;
        font-weight: 500;
        display: flex;
        position: relative;
        justify-content: space-between;

        .plus-container {
            padding-left: 16px;
            width: 16px;
            height: 16px;
            position: relative;

            >span {
                &:nth-child(1) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 2px;
                    height: 100%;
                    background-color: #1C2E3B;
                    transition: transform 0.35s ease;
                    transform: translate(-50%, -50%) rotate(90deg);
                }

                &:nth-child(2) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 2px;
                    background-color: #1C2E3B;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    
    .FAQuestion {
        background-color: #F7F9FA;
        overflow-y: hidden;
        transition: height 0.35s ease, padding 0.35s ease;
        padding-left: 24px;
        padding-right: 24px;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;

        a {
            color: $darkblue;
            font-weight: 700;
            text-decoration: none;
            transition: color 0.32s ease;

            &:hover {
                color: #9aabb8;
                transition: color 0.32s ease;
            }
        }
    }

    &:not(.open) {
        .FATitle {
            .plus-container {
                >span {
                    &:nth-child(1) {
                        transform: translate(-50%, -50%) rotate(0deg);
                    }
                }
            }
        }
    }
}