@import "../../App.scss";

.ProjectBackArrow {
    font-family: $inter;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    font-weight: 14px;
    color: #1C2E3B;
    text-decoration: none;

    >svg {
        margin-right: 8px;
        transform: rotateX(0);
        transition: transform 0.8s cubic-bezier(0.56, 0, 0.24, 1);
    }

    &:hover {
        color: #9AABB8;

        >svg {
            transform: rotateX(180deg);
        }
    }
}