.Profile {
    display: grid;

    .profile-content-container {
        position: fixed;
        display: block;
        width: 100%;
        background-color: #FFFFFF;
        height: calc(100vh - 83px - 56px);
        top: 56px;
        left: 0;
        overflow-y: auto;
    }
}

@media screen and (min-width: 900px) {
    .Profile {
        grid-template-columns: 204px 369px auto;

        .profile-content-container {
            position: static;
            padding: 48px 24px 20px;
            height: 100vh;
        }
    }
}

@media screen and (min-width: 1025px) {
    .Profile {
        grid-template-columns: 204px 369px auto;

        .profile-content-container {
            padding: 48px 48px 20px;
        }
    }
}