.LeftSideBarSubNav {
    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: 30px;
    margin-top: 32px;

    >a {
        font-size: 12px;
        font-weight: 500;
        color: #9AABB8;
        line-height: 24px;
        text-decoration: none;
        cursor: pointer;
        transition: color 0.35s ease;

        &.active, &:hover {
            color: #FFFFFF;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
}