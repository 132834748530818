@import "../../App.scss";

.ProfileUploadModal {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: $darkblue, $alpha: 0.85);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none;
    width: 100vw;
    height: 100vh;

    .close-button {
        position: absolute;
        top: 12px;
        right: 24px;
        width: 24px;
        height: 24px;
        cursor: pointer;

        >span {
            width: 100%;
            height: 2px;
            background-color: $darkblue;
            top: 50%;
            left: 50%;
            position: absolute;
            transition: background-color 0.18s ease;

            &:nth-child(1) {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:nth-child(2) {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        &:hover {
            >span {
                background-color: $skyblue;
            }
        }
    }

    .inner-wrapper {
        width: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        background-color: #EBEEF0;
        padding: 48px 24px 18px;
        border-radius: 4px;
        position: relative;

        h2 {
            margin-bottom: 24px;
        }

        .wrapper {
            border-radius: 50%;
            overflow: hidden;
            display: inline-block;
            border: none;
            width: 96px;
            height: 96px;
            position: relative;
            margin-bottom: 16px;
            background-color: $white;

            img {
                display: block;
                border: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        form {
            display: block;
            width: 100%;

            .file-wrapper {
                width: 100%;
                position: relative;
                padding: 8px 16px;
                border-radius: 4px;
                border: 1px solid #D7DDE0;
                background-color: $white;

                label {
                    display: block;
                    font-size: 12px;
                    line-height: 24px;
                    color: #66727A;
                    font-family: $inter;

                    &.error {
                        opacity: 0;
                        color: #ED1F1F;
                        transition: opacity 0.35s ease;

                        &.show {
                            opacity: 1;
                        }
                    }
                }
    
                input[type="file"] {
                    background-color: transparent;
                    display: block;
                    width: 100%;
                    border: none;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    color: $darkblue;
                    font-family: $inter;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
    
                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .button-container {
            .button {
                display: inline-block;
            }
        }
    }

    &.open {
        display: flex;
    }
}