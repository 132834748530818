@import '../../App.scss';

.PrivacyPolicy {
	display: grid;
    grid-template-columns: auto;

    h1 {
        margin-bottom: 40px;
    }

    h2 {
        margin-bottom: 24px;
    }

    h3 {
        margin-bottom: 16px;
    }

    p {
        margin-bottom: 12px;
    }

    ul, ol {
        margin-top: 8px;
        margin-bottom: 32px;

        >li:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}

@media screen and (min-width: 481px) {
    .PrivacyPolicy {
        grid-template-columns: 204px auto;
    }
}