@import "../../App.scss";

.MobileTopBar {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #FFFFFF;
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    z-index: 1000;

    .mobile-ascent-logo {
        margin-right: auto;
        font-weight: 500;
        font-family: $inter;
        font-size: 14px;
        line-height: 16px;
        color: #A49368;
        text-decoration: none;
    }

    .whats-new {
        margin-right: 24px;
        border-radius: 4px;
        background-color: #A49368;
        padding: 6px 8px;
        font-family: $inter;
        font-size: 12px;
        line-height: 14px;
        color: $darkblue;
        font-weight: 600;
        text-decoration: none;
    }

    .hamburger-wrapper {
        min-width: 24px;
    }

    &.hidden {
        display: none;
        
        .mobile-ascent-logo {
            display: none;
        }
    }
}

@media screen and (min-width: 900px) {
    .MobileTopBar {
        display: none;
    }
}