@import '../../App.scss';

.LargeUserProfile {
    display: flex;
    
    .img-wrapper {
        overflow: hidden;
        border-radius: 50%;
        margin-right: 16px;
        background-color: $white;
        height: 96px;
        width: 96px;
    }
    .profile-picture {
        display: block;
        height: 96px;
        width: 96px;
    }

    .info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 12px;
        margin-bottom: 24px;

        .username {
            font-size: 20px;
            color: #1C2E3B;
            font-weight: 600;
            font-family: $inter;
            line-height: 1;
            margin-bottom: 8px;
        }

        .tier {
            font-size: 14px;
            color: #576F80;
            line-height: 1;
            margin-bottom: 16px;
        }

        .upload-new-picture {
            color: #1C2E3B;
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            font-family: $inter;
            cursor: pointer;
            transition: color 0.35s ease;

            &:hover {
                color: #9AABB8;
            }
        }        
    }
}