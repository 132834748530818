@import "../../App.scss";

.MobileBottomBar {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 16px 34px;
    background-color: $darkblue;
    width: 100%;
    z-index: 1000;
    height: 83px;

    .nav-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        column-gap: 10px;
    }

    &.logged-out {
        background-color: #EBEEF0;
        padding-top: 34px;
        padding-bottom: 30px;
        height: 144px;
        position: static;

        .LeftSideBarPrivacyNav {
            margin-bottom: 12px;
        }
    }

    &.hidden {
        display: none;
    }
}

@media screen and (min-width: 900px) {
    .MobileBottomBar {
        display: none;
    }
}