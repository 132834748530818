.LeftSideBarPrivacyNav {
    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: 34px;

    >a {
        font-size: 10px;
        line-height: 20px;
        font-weight: 700;
        color: #6D808F;
        text-decoration: none;
        cursor: pointer;
        transition: color 0.35s ease;

        &.active, &:hover {
            color: #FFFFFF;
        }
    }

    &.blue {
        padding-left: 0;
        padding-right: 0;
        
        >a {
            color: #1C2E3B;

            &:hover, &.active {
                color: #BCD2E0;
            }
        }
    }
}