@import "../../App.scss";

.FormTextField {
    position: relative;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #D7DDE0;
    background-color: #FFFFFF;

    label {
        display: block;
        font-size: 12px;
        line-height: 24px;
        color: #66727A;
        font-family: $inter;
    }

    .form-error-label {
        position: absolute;
        font-size: 10px;
        font-family: $inter;
        left: 18px;
        bottom: 0;
        color: #ED1F1F;
        opacity: 0;
        transition: opacity 0.35s ease;

        &.has-error {
            opacity: 1;
        }
    }

    .input {
        background-color: transparent;
        display: block;
        width: 100%;
        border:none;
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        color: #1C2E3B;
        font-family: $inter;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus {
            outline: none;
        }
    }

    img {
        position: absolute;
        width: 24px;
        height: 24px;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
    }

    &.has-icon {
        input {
            padding-left: 32px;
        }
    }
}