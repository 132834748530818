@import '../../App.scss';

.DashboardSidebarRight {
    background-color: #CED9E0;
    padding: 50px 32px;
    height: 100vh;
    overflow-y: scroll;
    display: none;

    h2 {
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 1.2px;
        line-height: 1;
        color: #1C2E3B;
        margin: 0;
        margin-bottom: 40px;
    }

    .dashboard-content-container {
        >*:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}

@media screen and (min-width: 481px) {
    .DashboardSidebarRight {
        display: none;
    }
}

@media screen and (min-width: 1025px) {
    .DashboardSidebarRight {
        display: block;
        padding: 32px 16px;
    }
}

@media screen and (min-width: 1201px) {
    .DashboardSidebarRight {
        padding: 50px 32px 24px;
    }
}

@media screen and (max-height: 932px) {
    .DashboardSidebarRight {
        height: auto;
        overflow-y: auto;
    }
}