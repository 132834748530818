.router-content-wrapper {
    padding-top: 53px;
    padding-bottom: 83px;
    height: auto;

    &.home {
        padding-top: 0;
    }
}

@media screen and (min-width: 900px) {
    .router-content-wrapper {
        padding: 0;
    }
}