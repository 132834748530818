@import '../../App.scss';

.LeftSidebar {
	background:$darkblue;
    padding-top: 34px;
    //min-height: 100vh;
    height: 100vh;
    //overflow-y: scroll;
    padding-bottom: 12px;
    left: 0;
    width: 204px;
    display: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    left: 0;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

	.logo {
        text-decoration: none;
        margin-bottom: 34px;
        padding-left: 24px;
        padding-right: 24px;

        svg {
            margin-bottom:8px;
        }

        .app-name {
            font-family: $inter;
            font-size: 12px;
            color: #A49368;
            letter-spacing: 0;
            line-height: 12px;
            text-decoration: none;
            display: block;
        }
	}

	.logged-in-nav-small {
		margin-top:64px;
		a {
			display:block;
			margin-top:16px;
		}
	}
}

@media screen and (min-width: 900px) {
    .LeftSidebar {
        display: flex;
        flex-direction: column;
    }
}