@import '../../App.scss';

.Portfolio {
	display: grid;
    grid-template-columns: auto;

    .portfolio-content-container {
        display: block;
        column-gap: 24px;
        height: auto;
        grid-template-columns: auto;
        background-color: #EBEEF0;
        min-height: calc(100vh);

        .map-side-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 0;
            padding-top: 16px;
            padding-left: 16px;
            padding-right: 16px;
            column-gap: 16px;
    
            .portfolio-map-container {
                display: none;
                grid-column: 1 / 3;
            }
        }

        .project-side-container {
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
            
            .projects-wrapper {
                display: grid;
                grid-template-columns: auto;
                column-gap: 24px;
                row-gap: 24px;
                align-items: start;
            }
        }
    }
}

@media screen and (min-width: 481px) {
    .Portfolio {
        .portfolio-content-container {
            .project-side-container {
                .projects-wrapper {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .Portfolio {
        display: grid;
        grid-template-columns: 204px auto;

        .portfolio-content-container {
            grid-template-columns: 2fr 1fr;
            height: 100vh;
            display: grid;

            .map-side-container {
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 56px 1fr;
                row-gap: 24px;
                column-gap: 16px;
                padding-left: 32px;
                padding-bottom: 28px;
                padding-top: 24px;

                .portfolio-map-container {
                    display: block;
                }
            }

            .project-side-container {
                padding-right: 32px;
                padding-left: 0;
                overflow-y: scroll;
                padding-top: 104px;

                .projects-wrapper {
                    grid-template-columns: auto;
                    padding-bottom: 28px;
                }
            }
        }
    }
}

@media screen and (min-width: 1201px) {
    .Portfolio {
        .portfolio-content-container {
            grid-template-columns: 1fr 1fr;

            .project-side-container {
                .projects-wrapper {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
}