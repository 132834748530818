@import '../../App.scss';

.DashboardRank {
    display: grid;
    grid-template-columns:repeat(3, 1fr);
    grid-template-rows: 48px auto;
    margin-bottom: 16px;

    .categories {
        grid-column: 1 / 4;
        grid-row: 1;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        background-color: #FFFFFF;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        >* {
            &:nth-child(1) {

            }

            &:nth-child(2) {
                text-align: center;
            }
            
            &:nth-child(3) {
                text-align: center;
            }

            &:nth-child(4) {
                text-align: right;
            }
        }
    }

    .rankRow {
        grid-column: 1 / 4;
        grid-row: auto;
        display: grid;
        align-items: center;
        background-color: #F7F9FA;
        grid-template-columns: repeat(4, 1fr);
        padding: 16px 24px;

        >* {
            font-family: $inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: #576F80;

            &:nth-child(1) {
            }

            &:nth-child(2) {
                text-align: center;
            }

            &:nth-child(3) {
                text-align: center;
            }
            &:nth-child(4) {
                text-align: right;
            }
        }

        &.you {
            >* {
                color: #1C2E3B;
                font-weight: 600;
            }
        }
    }

    >.rankRow {
        &:not(:last-child) {
            margin-bottom: 1px;
        }

        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

@media screen and (min-width: 768px) {
    .DashboardRank {
        margin-bottom: 0;

        .rankRow {
            padding: 0;
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}