@import '../../App.scss';

.row {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    max-width: none;
    margin-left: -0.625rem;
    margin-right: -0.625rem; }
    @media screen and (min-width: 48.0625em) {
      .row .row {
        margin-left: -0.9375rem;
        margin-right: -0.9375rem; } }
  .row.expanded {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row {
    margin-left: 0;
    margin-right: 0; }

