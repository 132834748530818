.UserLogout {
    position: relative;
    padding-top: 22px;
    padding-bottom: 22px;
    color: #1C2E3B;
    font-size: 16px;
    line-height: 20px;
    background-color: #FFFFFF;
    font-weight: 600;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.35s ease, color 0.35s ease;

    svg {
        position: absolute;
        width: 24px;
        height: 24px;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);

        .fill-path {
            fill: #1C2E3B;
            transition: fill 0.35s ease;
        }
    }

    &:hover {
        background-color: #1C2E3B;
        color: #FFFFFF;

        svg {
            .fill-path {
                fill: #FFFFFF;
            }
        }
    }
}