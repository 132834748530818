@import '../../App.scss';

.ForgotPassword {
	display: block;
    grid-template-columns: auto;
    min-height: calc(100vh - 144px - 52px);
    background: $white;

    .forgot-password-content-container {
        padding: 16px;

        .form-container {
            max-width: 336px;
        }
    }
}

@media screen and (min-width: 900px) {
    .ForgotPassword {
        display: grid;
        grid-template-columns: 204px 369px auto;
        min-height: 100vh;

        .forgot-password-content-container {
            padding-top: 160px;
        }
    }
}

@media screen and (min-width: 1025px) {
    .ForgotPassword {
        .forgot-password-content-container {
            padding-left: 88px;
            padding-top: 160px;
            padding-bottom: 0;
            padding-right: 0;
        }
    }
}