@import '../../App.scss';

.PointsSummary {
    display: grid;
    grid-template-rows: 48px auto;
    grid-template-columns: auto;
    background-color: #F7F9FA;
    border-radius: 4px;
    grid-column: 1 / 6;
    grid-row: 4;
    overflow-x: scroll;
    justify-items: stretch;

    .earnings-container {
        grid-column: 1 / 6;
        grid-row: 1;
        align-items: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-color: #FFFFFF;
        padding-left: 24px;
        padding-right: 24px;
        display: grid;
        grid-template-columns: repeat(9, 127px);
        text-align: center;
        align-items: center;

        .earnings {
            text-align: left;
        }
    }

    .sales-rewards  {
        grid-column: 1 / 3;
        grid-row: 2;
        padding-top: 26px;
        padding-left: 24px;
        padding-bottom: 30px;
        display: grid;
        grid-column-gap: 30px;
        grid-template-columns: 24px auto;
        align-items: center;

        .label {
            font-family: $inter;
            font-size: 14px;
            color: #576F80;
            display: flex;
            align-items: center;
            line-height: 16px;

            .number {
                margin-right: 13px;
                font-size: 24px;
                letter-spacing: -1.32px;
                color: #1C2E3B;
                font-weight: 600;
            }
        }
    }

    .points-events {
        grid-column: 1 / 6;
        grid-row: 2;
        display: grid;
        row-gap: 32px;
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: auto;
        align-items: center;
        padding-bottom: 16px;
        padding-left:24px;
        padding-right: 24px;
        font-size: 14px;
        line-height: 16px;
        align-items: baseline;
        color: #576F80;

        >* {
            display: flex;
            align-items: center;
            height: 100%;
            padding-top: 16px;
            padding-bottom: 16px;
            /*padding-left: 10px;*/
            padding-right: 10px;
            border-bottom: 1px solid #EBEEF0;
            max-width: 1fr;

            &.last {
                border-bottom: none;
            }
        }

        .date {
            text-align: left;
            line-height: 16px;
            color: #617380;
        }

        .status {
            text-align: left;
            font-size: 14px;
            line-height: 16px;
            color: #1C2E3B;
            font-weight: 600;
        }

        .points {
            text-align: left;
        }

        .purchaser {
            text-align: left;
            line-height: 16px;
        }

        .empty {
            text-align: center;
        }
    }
}

@media screen and (min-width: 1201px) {
    .PointsSummary {
        grid-template-columns: repeat(5, 1fr);

        .earnings-container {
            grid-template-columns: repeat(9, 1fr);
        }
    }
}