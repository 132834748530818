@import "../../App.scss";

@keyframes slideRight {
    from {
        right: 100%;
    }

    to {
        right: -20%;
    }
}

.FormSubmitField {
    margin-top: 16px;
    position: relative;

    img {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
    }

    input {
        width: 160px;
        height: 48px;
        opacity: 1;
        border: none;
        font-family: $inter;
        font-size: 14px;
        font-weight: 600;
        color: #FFFFFF;
        transition: 0.35s ease all;
        cursor: pointer;
        position: relative;
        background-color: transparent;
        z-index: 2;

        &:hover {
            color: #1C2E3B;
        }
    }

    .submit-wrapper {
        border-radius: 4px;
        background-color: #1C2E3B;
        transition: 0.35s ease all;
        display: inline-block;

        &:hover {
            background-color: #EBEEF0;
        }
    }

    &.submitting {

        input {
            color:$darkblue;
            background-color: transparent;
            opacity: 0.6;
        }

        .submit-wrapper {
            position: relative;
            overflow: hidden;
            background-color: #EBEEF0;
            
            &::after {
                content: "";
                display: block;
                z-index: 1;
                animation: slideRight 1.8s linear infinite;
                position: absolute;
                top: 0;
                height: 100%;
                right: 100%;
                background-color: darken(#EBEEF0, 2.5%);
                width: 10px;
                box-shadow: 0 -3px lighten(darken(#EBEEF0, 1%), 2%);
            }
        }
    }
}