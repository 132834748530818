@import '../../App.scss';

.DashboardSummary {
    display: block;
    background-color: #1C2E3B;
    padding-bottom: 32px;
    border-radius: 4px;
    margin-bottom: 16px;

    .icon-container {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #0E181F;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 17px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        position: relative;

        .arrow {
            position: absolute;
            top: 50%;
            right: 0;
            height: 100%;
            width: auto;
            transform: translateY(-50%);
            object-fit: cover;
            display: none;
        }

        .tier-wrapper {
            margin-right: 56px;

            img {
                display: block;
                width: 84px;
                height: auto;
            }

            h3 {
                font-family: $inter;
                font-size: 12px;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: left;
                line-height: 14px;
                margin: 0;
                margin-bottom: 12px;
                text-align: center;
            }
        }

        .points-container {
            h4 {
                font-family: $inter;
                font-size: 14px;
                color: #9AABB8;
                letter-spacing: 0;
                line-height: 14px;
                margin: 0;
                margin-bottom: 12px;
            }
    
            .points-total {
                font-family: $inter;
                font-size: 36px;
                color: #FFFFFF;
                letter-spacing: -3.52px;
                font-weight:bold;
                min-width: 160px;
            }
        }
    }

    .points-to-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 24px;
        padding-right: 24px;
        padding-left: 24px;

        .points-to {
            font-family: $inter;
            font-size: 12px;
            color: #9AABB8;
            letter-spacing: 0;
            line-height: 20px;
            strong {
                color: #FFFFFF;
            }
        }

        .points-to-number {
            font-family: $inter;
            font-weight:bold;
            font-size: 24px;
            color: #FFFFFF;
            letter-spacing: -0.99px;
            margin-bottom: 24px;
        }

        .view-tier-details {
            display: flex;
            align-items: center;
            font-family: $inter;
            font-weight:bold;
            font-size: 12px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 14px;
            text-decoration: none;
            transition: color 0.35s ease;

            img {
                margin-right: 8px;
            }

            &:hover {
                color: #9AABB8;
            }
        }
    }

    .slider-container {
        grid-column: auto;
        padding-top: 32px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 12px;
    }

    .tier-overview-nobar {
        font-family: $inter;
        font-size: 14px;
        color: #9AABB8;
        letter-spacing: 0;
        line-height: 24px;
        padding:24px 24px 0px 24px;
    }

    .tier-overview-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column: 1 / 3;
        padding-left: 27px;
        padding-right: 27px;

        .this-tier {
            font-family: $inter;
            font-size: 14px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 14px;
            font-weight: bold;

            span {
                display: block;
                font-weight: normal;
                color: #6D808F;
                letter-spacing: -0.64px;
                margin-top: 4px;
            }
        }

        .next-tier {
            font-family: $inter;
            font-size: 14px;
            color: #9AABB8;
            letter-spacing: 0;
            text-align: right;
            line-height: 14px;

            strong {
                color: #FFFFFF;
            }

            span {
                display: block;
                font-size: 12px;
                color: #6D808F;
                letter-spacing: -0.64px;
                margin-top: 4px;
            }
        }
    }
}

.DashboardSummerySlider {
    position: relative;

    .line {
        width: 100%;
        height: 8px;
        background-color: #2A4152;
    }

    .overlay-points {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        font-family: $inter;
        font-size: 12px;
        letter-spacing: -0.64px;
        line-height: 14px;
        color: #1C2E3B;
        padding: 1px 7px;
        border-radius: 8px;
        background-color: #FFFFFF;
        display: inline-block;
        z-index: 10;
    }

    .line-colour-cover {
        height: 8px;
        background-color: #7860B8;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    .oval {
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #7860B8;
        transform: translate(-50%, -50%);
    }

    .end-oval {
        position: absolute;
        top: 50%;
        right: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #2A4152;
        transform: translate(50%, -50%);
        
        .inner-piece {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            background-color: #1C2E3B;
        }
    }
}

@media screen and (min-width: 700px) {
    .DashboardSummary {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;
        padding-bottom: 40px;
        margin-bottom: 0;

        .icon-container {
            padding-top: 42px;
            padding-left: 27px;
            padding-bottom: 32px;
            padding-right: 0;
            border-top-right-radius: 0;
            justify-content: flex-start;

            .arrow {
                display: block;
            }

            .tier-wrapper {
                margin-right: 29px;

                img {
                    width: 124px;
                }
            }
        }

        .points-to-container {
            display: block;
            padding-top: 42px;
            padding-right: 27px;
            padding-left: 27px;
            border-bottom: 1px solid #0E181F;

            .points-total {
                font-size: 48px;
            }

            .points-to-number {
                margin-bottom: 32px;
            }
        }

        .slider-container {
            grid-column: 1 / 3;
            padding-top: 24px;
            padding-left: 35px;
            padding-right: 35px;
            padding-bottom: 12px;
        }
    }
}

@media screen and (min-width: 1201px) {
    .DashboardSummary {
        .icon-container {
            .points-container {
                .points-total {
                    font-size: 64px;
                }
            }
        }

        .points-to-container {
            padding-left: 64px;
        }
    }
}