@import '../../App.scss';

.BuilderStory {
	p {
		font-family: $inter;
		font-size: 16px;
		color: #576F80;
		letter-spacing: 0;
		line-height: 24px;
        margin-bottom: 24px;
	}

    display: grid;
    grid-template-columns: auto;

    video {
        display: block;
        height: auto;
        max-width: 100%;
    }

    img.logo {
        margin-bottom: 32px;
    }

    h1 {
        margin-bottom: 40px;
    }
}

@media screen and (min-width: 900px) {
    .BuilderStory {
        grid-template-columns: 204px auto;
    }
}