@import '../../App.scss';

.Col {
}

.column, .columns {
  flex: 1 1 0px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  min-width: initial; }
  @media screen and (min-width: 48.0625em) {
    .column, .columns {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }
  .row .column.row.row, .row .row.row.columns {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0; }

.xxsmall-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.xxsmall-offset-0 {
  margin-left: 0%; }

.xxsmall-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.xxsmall-offset-1 {
  margin-left: 8.3333333333%; }

.xxsmall-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.xxsmall-offset-2 {
  margin-left: 16.6666666667%; }

.xxsmall-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.xxsmall-offset-3 {
  margin-left: 25%; }

.xxsmall-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.xxsmall-offset-4 {
  margin-left: 33.3333333333%; }

.xxsmall-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.xxsmall-offset-5 {
  margin-left: 41.6666666667%; }

.xxsmall-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.xxsmall-offset-6 {
  margin-left: 50%; }

.xxsmall-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.xxsmall-offset-7 {
  margin-left: 58.3333333333%; }

.xxsmall-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.xxsmall-offset-8 {
  margin-left: 66.6666666667%; }

.xxsmall-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.xxsmall-offset-9 {
  margin-left: 75%; }

.xxsmall-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.xxsmall-offset-10 {
  margin-left: 83.3333333333%; }

.xxsmall-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.xxsmall-offset-11 {
  margin-left: 91.6666666667%; }

.xxsmall-order-1 {
  order: 1; }

.xxsmall-order-2 {
  order: 2; }

.xxsmall-order-3 {
  order: 3; }

.xxsmall-order-4 {
  order: 4; }

.xxsmall-order-5 {
  order: 5; }

.xxsmall-order-6 {
  order: 6; }

.xxsmall-up-1 {
  flex-wrap: wrap; }
  .xxsmall-up-1 > .column, .xxsmall-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.xxsmall-up-2 {
  flex-wrap: wrap; }
  .xxsmall-up-2 > .column, .xxsmall-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.xxsmall-up-3 {
  flex-wrap: wrap; }
  .xxsmall-up-3 > .column, .xxsmall-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

.xxsmall-up-4 {
  flex-wrap: wrap; }
  .xxsmall-up-4 > .column, .xxsmall-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.xxsmall-up-5 {
  flex-wrap: wrap; }
  .xxsmall-up-5 > .column, .xxsmall-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.xxsmall-up-6 {
  flex-wrap: wrap; }
  .xxsmall-up-6 > .column, .xxsmall-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

.xxsmall-up-7 {
  flex-wrap: wrap; }
  .xxsmall-up-7 > .column, .xxsmall-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%; }

.xxsmall-up-8 {
  flex-wrap: wrap; }
  .xxsmall-up-8 > .column, .xxsmall-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.xxsmall-collapse > .column, .xxsmall-collapse > .columns {
  padding-left: 0;
  padding-right: 0; }

.xxsmall-uncollapse > .column, .xxsmall-uncollapse > .columns {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }

@media screen and (min-width: 20.0625em) {
  .xsmall-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .xsmall-offset-0 {
    margin-left: 0%; }

  .xsmall-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .xsmall-offset-1 {
    margin-left: 8.3333333333%; }

  .xsmall-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .xsmall-offset-2 {
    margin-left: 16.6666666667%; }

  .xsmall-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .xsmall-offset-3 {
    margin-left: 25%; }

  .xsmall-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .xsmall-offset-4 {
    margin-left: 33.3333333333%; }

  .xsmall-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .xsmall-offset-5 {
    margin-left: 41.6666666667%; }

  .xsmall-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .xsmall-offset-6 {
    margin-left: 50%; }

  .xsmall-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .xsmall-offset-7 {
    margin-left: 58.3333333333%; }

  .xsmall-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .xsmall-offset-8 {
    margin-left: 66.6666666667%; }

  .xsmall-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .xsmall-offset-9 {
    margin-left: 75%; }

  .xsmall-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .xsmall-offset-10 {
    margin-left: 83.3333333333%; }

  .xsmall-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .xsmall-offset-11 {
    margin-left: 91.6666666667%; }

  .xsmall-order-1 {
    order: 1; }

  .xsmall-order-2 {
    order: 2; }

  .xsmall-order-3 {
    order: 3; }

  .xsmall-order-4 {
    order: 4; }

  .xsmall-order-5 {
    order: 5; }

  .xsmall-order-6 {
    order: 6; }

  .xsmall-up-1 {
    flex-wrap: wrap; }
    .xsmall-up-1 > .column, .xsmall-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }

  .xsmall-up-2 {
    flex-wrap: wrap; }
    .xsmall-up-2 > .column, .xsmall-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }

  .xsmall-up-3 {
    flex-wrap: wrap; }
    .xsmall-up-3 > .column, .xsmall-up-3 > .columns {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; }

  .xsmall-up-4 {
    flex-wrap: wrap; }
    .xsmall-up-4 > .column, .xsmall-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }

  .xsmall-up-5 {
    flex-wrap: wrap; }
    .xsmall-up-5 > .column, .xsmall-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }

  .xsmall-up-6 {
    flex-wrap: wrap; }
    .xsmall-up-6 > .column, .xsmall-up-6 > .columns {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; }

  .xsmall-up-7 {
    flex-wrap: wrap; }
    .xsmall-up-7 > .column, .xsmall-up-7 > .columns {
      flex: 0 0 14.2857142857%;
      max-width: 14.2857142857%; }

  .xsmall-up-8 {
    flex-wrap: wrap; }
    .xsmall-up-8 > .column, .xsmall-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }
@media screen and (min-width: 20.0625em) and (min-width: 20.0625em) {
  .xsmall-expand {
    flex: 1 1 0px; } }
.row.xsmall-unstack > .column, .row.xsmall-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 20.0625em) {
    .row.xsmall-unstack > .column, .row.xsmall-unstack > .columns {
      flex: 1 1 0px; } }
@media screen and (min-width: 20.0625em) {
  .xsmall-collapse > .column, .xsmall-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }

  .xsmall-uncollapse > .column, .xsmall-uncollapse > .columns {
    padding-left: 0.625rem;
    padding-right: 0.625rem; } }
@media screen and (min-width: 30.0625em) {
  .small-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .small-offset-0 {
    margin-left: 0%; }

  .small-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .small-offset-1 {
    margin-left: 8.3333333333%; }

  .small-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .small-offset-2 {
    margin-left: 16.6666666667%; }

  .small-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .small-offset-3 {
    margin-left: 25%; }

  .small-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .small-offset-4 {
    margin-left: 33.3333333333%; }

  .small-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .small-offset-5 {
    margin-left: 41.6666666667%; }

  .small-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .small-offset-6 {
    margin-left: 50%; }

  .small-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .small-offset-7 {
    margin-left: 58.3333333333%; }

  .small-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .small-offset-8 {
    margin-left: 66.6666666667%; }

  .small-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .small-offset-9 {
    margin-left: 75%; }

  .small-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .small-offset-10 {
    margin-left: 83.3333333333%; }

  .small-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .small-offset-11 {
    margin-left: 91.6666666667%; }

  .small-order-1 {
    order: 1; }

  .small-order-2 {
    order: 2; }

  .small-order-3 {
    order: 3; }

  .small-order-4 {
    order: 4; }

  .small-order-5 {
    order: 5; }

  .small-order-6 {
    order: 6; }

  .small-up-1 {
    flex-wrap: wrap; }
    .small-up-1 > .column, .small-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }

  .small-up-2 {
    flex-wrap: wrap; }
    .small-up-2 > .column, .small-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }

  .small-up-3 {
    flex-wrap: wrap; }
    .small-up-3 > .column, .small-up-3 > .columns {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; }

  .small-up-4 {
    flex-wrap: wrap; }
    .small-up-4 > .column, .small-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }

  .small-up-5 {
    flex-wrap: wrap; }
    .small-up-5 > .column, .small-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }

  .small-up-6 {
    flex-wrap: wrap; }
    .small-up-6 > .column, .small-up-6 > .columns {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; }

  .small-up-7 {
    flex-wrap: wrap; }
    .small-up-7 > .column, .small-up-7 > .columns {
      flex: 0 0 14.2857142857%;
      max-width: 14.2857142857%; }

  .small-up-8 {
    flex-wrap: wrap; }
    .small-up-8 > .column, .small-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }
@media screen and (min-width: 30.0625em) and (min-width: 30.0625em) {
  .small-expand {
    flex: 1 1 0px; } }
.row.small-unstack > .column, .row.small-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 30.0625em) {
    .row.small-unstack > .column, .row.small-unstack > .columns {
      flex: 1 1 0px; } }
@media screen and (min-width: 30.0625em) {
  .small-collapse > .column, .small-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }

  .small-uncollapse > .column, .small-uncollapse > .columns {
    padding-left: 0.625rem;
    padding-right: 0.625rem; } }
@media screen and (min-width: 40.0625em) {
  .medium-small-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .medium-small-offset-0 {
    margin-left: 0%; }

  .medium-small-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .medium-small-offset-1 {
    margin-left: 8.3333333333%; }

  .medium-small-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .medium-small-offset-2 {
    margin-left: 16.6666666667%; }

  .medium-small-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .medium-small-offset-3 {
    margin-left: 25%; }

  .medium-small-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .medium-small-offset-4 {
    margin-left: 33.3333333333%; }

  .medium-small-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .medium-small-offset-5 {
    margin-left: 41.6666666667%; }

  .medium-small-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .medium-small-offset-6 {
    margin-left: 50%; }

  .medium-small-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .medium-small-offset-7 {
    margin-left: 58.3333333333%; }

  .medium-small-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .medium-small-offset-8 {
    margin-left: 66.6666666667%; }

  .medium-small-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .medium-small-offset-9 {
    margin-left: 75%; }

  .medium-small-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .medium-small-offset-10 {
    margin-left: 83.3333333333%; }

  .medium-small-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .medium-small-offset-11 {
    margin-left: 91.6666666667%; }

  .medium-small-order-1 {
    order: 1; }

  .medium-small-order-2 {
    order: 2; }

  .medium-small-order-3 {
    order: 3; }

  .medium-small-order-4 {
    order: 4; }

  .medium-small-order-5 {
    order: 5; }

  .medium-small-order-6 {
    order: 6; }

  .medium-small-up-1 {
    flex-wrap: wrap; }
    .medium-small-up-1 > .column, .medium-small-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }

  .medium-small-up-2 {
    flex-wrap: wrap; }
    .medium-small-up-2 > .column, .medium-small-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }

  .medium-small-up-3 {
    flex-wrap: wrap; }
    .medium-small-up-3 > .column, .medium-small-up-3 > .columns {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; }

  .medium-small-up-4 {
    flex-wrap: wrap; }
    .medium-small-up-4 > .column, .medium-small-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }

  .medium-small-up-5 {
    flex-wrap: wrap; }
    .medium-small-up-5 > .column, .medium-small-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }

  .medium-small-up-6 {
    flex-wrap: wrap; }
    .medium-small-up-6 > .column, .medium-small-up-6 > .columns {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; }

  .medium-small-up-7 {
    flex-wrap: wrap; }
    .medium-small-up-7 > .column, .medium-small-up-7 > .columns {
      flex: 0 0 14.2857142857%;
      max-width: 14.2857142857%; }

  .medium-small-up-8 {
    flex-wrap: wrap; }
    .medium-small-up-8 > .column, .medium-small-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }
@media screen and (min-width: 40.0625em) and (min-width: 40.0625em) {
  .medium-small-expand {
    flex: 1 1 0px; } }
.row.medium-small-unstack > .column, .row.medium-small-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 40.0625em) {
    .row.medium-small-unstack > .column, .row.medium-small-unstack > .columns {
      flex: 1 1 0px; } }
@media screen and (min-width: 40.0625em) {
  .medium-small-collapse > .column, .medium-small-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }

  .medium-small-uncollapse > .column, .medium-small-uncollapse > .columns {
    padding-left: 0.625rem;
    padding-right: 0.625rem; } }
@media screen and (min-width: 48.0625em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .medium-offset-0 {
    margin-left: 0%; }

  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .medium-offset-1 {
    margin-left: 8.3333333333%; }

  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .medium-offset-2 {
    margin-left: 16.6666666667%; }

  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .medium-offset-3 {
    margin-left: 25%; }

  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .medium-offset-4 {
    margin-left: 33.3333333333%; }

  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .medium-offset-5 {
    margin-left: 41.6666666667%; }

  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .medium-offset-6 {
    margin-left: 50%; }

  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .medium-offset-7 {
    margin-left: 58.3333333333%; }

  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .medium-offset-8 {
    margin-left: 66.6666666667%; }

  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .medium-offset-9 {
    margin-left: 75%; }

  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .medium-offset-10 {
    margin-left: 83.3333333333%; }

  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .medium-offset-11 {
    margin-left: 91.6666666667%; }

  .medium-order-1 {
    order: 1; }

  .medium-order-2 {
    order: 2; }

  .medium-order-3 {
    order: 3; }

  .medium-order-4 {
    order: 4; }

  .medium-order-5 {
    order: 5; }

  .medium-order-6 {
    order: 6; }

  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }

  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }

  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; }

  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }

  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }

  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; }

  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.2857142857%;
      max-width: 14.2857142857%; }

  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }
@media screen and (min-width: 48.0625em) and (min-width: 48.0625em) {
  .medium-expand {
    flex: 1 1 0px; } }
.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 48.0625em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }
@media screen and (min-width: 48.0625em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }

  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; } }
@media screen and (min-width: 64.0625em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .large-offset-0 {
    margin-left: 0%; }

  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .large-offset-1 {
    margin-left: 8.3333333333%; }

  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .large-offset-2 {
    margin-left: 16.6666666667%; }

  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .large-offset-3 {
    margin-left: 25%; }

  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .large-offset-4 {
    margin-left: 33.3333333333%; }

  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .large-offset-5 {
    margin-left: 41.6666666667%; }

  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .large-offset-6 {
    margin-left: 50%; }

  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .large-offset-7 {
    margin-left: 58.3333333333%; }

  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .large-offset-8 {
    margin-left: 66.6666666667%; }

  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .large-offset-9 {
    margin-left: 75%; }

  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .large-offset-10 {
    margin-left: 83.3333333333%; }

  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .large-offset-11 {
    margin-left: 91.6666666667%; }

  .large-order-1 {
    order: 1; }

  .large-order-2 {
    order: 2; }

  .large-order-3 {
    order: 3; }

  .large-order-4 {
    order: 4; }

  .large-order-5 {
    order: 5; }

  .large-order-6 {
    order: 6; }

  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }

  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }

  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; }

  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }

  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }

  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; }

  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.2857142857%;
      max-width: 14.2857142857%; }

  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }
@media screen and (min-width: 64.0625em) and (min-width: 64.0625em) {
  .large-expand {
    flex: 1 1 0px; } }
.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 64.0625em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }
@media screen and (min-width: 64.0625em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }

  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; } }
@media screen and (min-width: 75.0625em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .xlarge-offset-0 {
    margin-left: 0%; }

  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .xlarge-offset-1 {
    margin-left: 8.3333333333%; }

  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .xlarge-offset-2 {
    margin-left: 16.6666666667%; }

  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .xlarge-offset-3 {
    margin-left: 25%; }

  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .xlarge-offset-4 {
    margin-left: 33.3333333333%; }

  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .xlarge-offset-5 {
    margin-left: 41.6666666667%; }

  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .xlarge-offset-6 {
    margin-left: 50%; }

  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .xlarge-offset-7 {
    margin-left: 58.3333333333%; }

  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .xlarge-offset-8 {
    margin-left: 66.6666666667%; }

  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .xlarge-offset-9 {
    margin-left: 75%; }

  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .xlarge-offset-10 {
    margin-left: 83.3333333333%; }

  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .xlarge-offset-11 {
    margin-left: 91.6666666667%; }

  .xlarge-order-1 {
    order: 1; }

  .xlarge-order-2 {
    order: 2; }

  .xlarge-order-3 {
    order: 3; }

  .xlarge-order-4 {
    order: 4; }

  .xlarge-order-5 {
    order: 5; }

  .xlarge-order-6 {
    order: 6; }

  .xlarge-up-1 {
    flex-wrap: wrap; }
    .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }

  .xlarge-up-2 {
    flex-wrap: wrap; }
    .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }

  .xlarge-up-3 {
    flex-wrap: wrap; }
    .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; }

  .xlarge-up-4 {
    flex-wrap: wrap; }
    .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }

  .xlarge-up-5 {
    flex-wrap: wrap; }
    .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }

  .xlarge-up-6 {
    flex-wrap: wrap; }
    .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; }

  .xlarge-up-7 {
    flex-wrap: wrap; }
    .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
      flex: 0 0 14.2857142857%;
      max-width: 14.2857142857%; }

  .xlarge-up-8 {
    flex-wrap: wrap; }
    .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }
@media screen and (min-width: 75.0625em) and (min-width: 75.0625em) {
  .xlarge-expand {
    flex: 1 1 0px; } }
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 75.0625em) {
    .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
      flex: 1 1 0px; } }
@media screen and (min-width: 75.0625em) {
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }

  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; } }
@media screen and (min-width: 90.0625em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .xxlarge-offset-0 {
    margin-left: 0%; }

  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .xxlarge-offset-1 {
    margin-left: 8.3333333333%; }

  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .xxlarge-offset-2 {
    margin-left: 16.6666666667%; }

  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .xxlarge-offset-3 {
    margin-left: 25%; }

  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .xxlarge-offset-4 {
    margin-left: 33.3333333333%; }

  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .xxlarge-offset-5 {
    margin-left: 41.6666666667%; }

  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .xxlarge-offset-6 {
    margin-left: 50%; }

  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .xxlarge-offset-7 {
    margin-left: 58.3333333333%; }

  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .xxlarge-offset-8 {
    margin-left: 66.6666666667%; }

  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .xxlarge-offset-9 {
    margin-left: 75%; }

  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .xxlarge-offset-10 {
    margin-left: 83.3333333333%; }

  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .xxlarge-offset-11 {
    margin-left: 91.6666666667%; }

  .xxlarge-order-1 {
    order: 1; }

  .xxlarge-order-2 {
    order: 2; }

  .xxlarge-order-3 {
    order: 3; }

  .xxlarge-order-4 {
    order: 4; }

  .xxlarge-order-5 {
    order: 5; }

  .xxlarge-order-6 {
    order: 6; }

  .xxlarge-up-1 {
    flex-wrap: wrap; }
    .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }

  .xxlarge-up-2 {
    flex-wrap: wrap; }
    .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }

  .xxlarge-up-3 {
    flex-wrap: wrap; }
    .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; }

  .xxlarge-up-4 {
    flex-wrap: wrap; }
    .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }

  .xxlarge-up-5 {
    flex-wrap: wrap; }
    .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }

  .xxlarge-up-6 {
    flex-wrap: wrap; }
    .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; }

  .xxlarge-up-7 {
    flex-wrap: wrap; }
    .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
      flex: 0 0 14.2857142857%;
      max-width: 14.2857142857%; }

  .xxlarge-up-8 {
    flex-wrap: wrap; }
    .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }
@media screen and (min-width: 90.0625em) and (min-width: 90.0625em) {
  .xxlarge-expand {
    flex: 1 1 0px; } }
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 90.0625em) {
    .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
      flex: 1 1 0px; } }
@media screen and (min-width: 90.0625em) {
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }

  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; } }