@import '../../App.scss';

.DashboardAnnouncement {
	background:$gold;
	border-radius:4px;
	padding: 21px 24px;
	position: relative;
    margin-bottom: 16px;
    
	.background-image {
		position:absolute;
		top:0px;
		left:0px;
        width: 50px;
        height: 48px;
	}
	h2 {
		font-family: $inter;
		font-size: 24px;
		color: #1C2E3B;
		letter-spacing: 0;
		line-height: 14px;
        text-align: center;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        
        span {
            margin-left: auto;
        }

        svg {
            margin-left: auto;
            width: 12px;
            height: 7px;
        }
	}
	.bold-copy {
		font-family: $inter;
		font-weight:700;
		font-size: 14px;
		color: #1C2E3B;
		letter-spacing: 0;
		line-height: 24px;
        text-align: center;
        margin-bottom: 8px;
	}

	.copy {
		font-family: $inter;
		font-size: 14px;
		color: #1C2E3B;
		letter-spacing: 0;
		line-height: 24px;
        text-align: center;
	}

    &.closed {
        h2 {
            margin-bottom: 0;
        }
    }
}

@media screen and (min-width: 700px) {
    .DashboardAnnouncement {
        margin-bottom: 0;
        padding:72px 24px 24px 24px;

        h2 {
            text-align: left;
            
            span {
                margin-left: 0;
            }

            svg {
                display: none;
            }
        }

        .bold-copy {
            text-align: left;
        }
        
        .copy {
            text-align: left;
        }
    }
}
