@import "../../App.scss";

.PopupMessages {
    position: fixed;
    top: 0;
    right: 16px;
    transform: translateY(-100%);
    z-index: 99999;
    transition: top 0.35s ease, transform 0.35s ease;

    .popup-wrapper {
        top: 0;
        width: 340px;
        box-shadow: 0 11px 16px rbga(0 0 0 / 0.24);
        display: flex;
        padding: 24px 20px;
        border-radius: 4px;
        z-index: 1001;
        position: relative;
        background-color: #FFC952;
    }

    .icon-container {
        min-width: 24px;
        margin-right: 12px;

        .icon {
            width: 24px;
            height: 24px;
        }
    }

    .content-container {
        .title {
            font-size: 16px;
            line-height: 24px;
            color: #1C2E3B;
            font-weight: 600;
            font-family: $inter;
            margin-bottom: 4px;
        }

        .copy {
            font-size: 14px;
            line-height: 20px;
            color: #1C2E3B;
            font-family: $inter;
        }
    }

    .stack-1, .stack-2 {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $white;
        border-radius: 4px;
        border-bottom: 1px solid #FFC952;
        border-right: 1px solid #FFC952;
        background-color: darken(#FFC952, 5%);
        transform: translateY(-100%);
        top: -24px;
        transition: top 0.35s ease, transform 0.35s ease;
    }
    
    .stack-1 {
        width: calc(100%);
        z-index: 1004;
        left: 4px;
    }

    .stack-2 {
        width: calc(100%);
        left: 8px;
    }

    &.one-stack {
        .popup-wrapper {
            top: -4px;
        }

        .stack-1 {
            transform: translateY(0);
        }

        .stack-1 {
            top: 0;
            z-index: 1000;
        }

        .stack-2 {
            z-index: 1005;
        }
    }

    &.two-stack {
        .stack-1, .stack-2 {
            transform: translateY(0);
        }

        .popup-wrapper {
            top: -8px;
        }

        .stack-1 {
            top: -4px;
            z-index: 1000;
        }

        .stack-2 {
            top: 0;
            z-index: 999;
        }
    }

    &.success {
        .popup-wrapper {
            background-color: #45A42D;
        }

        .stack-1, .stack-2 {
            border-top-color: #45A42D;
            border-right-color: #45A42D;
            background-color: darken(#45A42D, 5%);
        }
        
    }

    &.error {
        .popup-wrapper {
            background-color: #ED1F1F;
        }

        .stack-1, .stack-2 {
            border-top-color: #ED1F1F;
            border-right-color: #ED1F1F;
            background-color: darken(#ED1F1F, 5%);
        }
    }

    &.open {
        top: 24px;
        transform: translateY(0);
    }
}