@import "../../App.scss";

.SmallUserProfile {
    display: flex;
    margin-left: 16px;
    margin-right: 16px;
    padding: 10px 16px 6px;
    cursor: pointer;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.35s ease;

    .image-wrapper {
        overflow: hidden;
        border-radius: 50%;
        margin-right: 8px;
        width: 48px;
        height: 48px;
        background-color: $white;

        .profile-picture {
            display: block;
            width: 48px;
            height: 48px;
            pointer-events: none;
        }
    }

    .info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .username {
            font-size: 14px;
            font-weight: 700;
            color: #FFFFFF;
            line-height: 1;
            margin-bottom: 2px;
            pointer-events: none;
        }

        .tier {
            font-size: 12px;
            font-weight: 400;
            color: #A2B4C2;
            line-height: 1;
            pointer-events: none;
        }
    }

    &.active, &:hover {
        background-color: #2A4152;
        cursor: pointer;
    }
}