@import "../../App.scss";

.AccountVerified {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 144px - 52px);

    .LeftSideBarPrivacyNav {
        margin-top: auto;
    }

    .UserLogout {
        margin-bottom: 0;
    }

    .content-container {
        padding: 32px 16px;
        background-color: $white;
        flex: 1 1 auto;

        .account-verified-wrapper {
            padding: 40px 24px 16px;
            background-color: #A49368;
            border-radius: 4px;

            img {
                margin-bottom: 40px;
            }

            .heading {
                margin: 0;
                color: #1C2E3B;
                margin-bottom: 14px;
                font-family: $inter;
                font-weight: 200;
                font-size: 32px;
                letter-spacing: 1.28px;
                line-height: 48px;
                max-width: 424px;
                display: block;
                margin-bottom: 30px;
            }

            p {
                margin: 0;
                color: #1C2E3B;
                font-family: $inter;
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                max-width: 605px;
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .AccountVerified {
        display: grid;
        grid-template-columns: 160px 1fr 2fr;
        min-height: 100vh;
    }
}

@media screen and (min-width: 1025px) {
    .AccountVerified {
        grid-template-columns: 204px 369px auto;

        .UserLogout {
            margin-bottom:  32px;
        }

        .content-container {
            padding: 42px 48px;

            .account-verified-wrapper {
                padding: 40px;

                .heading {
                    font-size: 40px;
                    letter-spacing: 1.6px;
                }

                p {
                    font-size: 24px;
                }
            }
        }
    }
}