@import '../../App.scss';

.Home {
    background: $darkblue;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

@media screen and (min-width: 481px) {
    .Home {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}