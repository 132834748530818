.Form {
    row-gap: 16px;
    column-gap: 20px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto;
    position: relative;
}

.heading {
    font-size: 24px;
    color: #1C2E3B;
    font-weight: 200;
    letter-spacing: 1.2px;
    line-height: 1;
    margin: 0;
    margin-bottom: 8px;
}

.bottom-heading {
    margin-top: 42px;
}

.password-bg {
    position: absolute;
    width: calc(100% + 48px);
    left: 50%;
    transform: translateX(-50%);
    height: 98px;
    background-color: #EBEEF0;
}

@media screen and (min-width: 481px) {
    .Form {
        grid-template-columns: 1fr 1fr;
        
    }

    .FormField {
        &.half {
            grid-column: span 1;
        }
        &.full {
            grid-column: span 2;
        }
    }
}

@media screen and (min-width: 900px) {
    .Form {
        grid-template-columns: auto;
    }

    .FormField {
        &.half {
            grid-column: span 1;
        }
        &.full {
            grid-column: span 1;
        }
    }
}

@media screen and (min-width: 1025px) {
    .Form {
        grid-template-columns: 1fr 1fr;
    }

    .FormField {
        &.half {
            grid-column: span 1;
        }
        &.full {
            grid-column: span 2;
        }
    }
}