@import '../../App.scss';

.AccountCreated {
    display: flex;
    flex-direction: column;
    grid-template-columns: auto;
    //height: calc(100vh - 144px - 52px);
    height: auto;

    img {
		max-width:100%;
	}

    .LeftSideBarPrivacyNav {
        margin-top: auto;
    }

    .content-container {
        background-color: #FFFFFF;
        padding: 32px 16px;
        flex: 1 1 auto;

        .account-created-wrapper {
            padding: 40px 24px 16px;
            background-color: #A49368;
            border-radius: 4px;

            h2 {
                margin: 0;
                color: #1C2E3B;
                margin-bottom: 14px;
                font-family: $inter;
                font-weight: 200;
                font-size: 32px;
                letter-spacing: 1.6px;
                line-height: 60px;
            }

            p {
                margin: 0;
                color: #1C2E3B;
                font-family: $inter;
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                margin-bottom: 8px;
            }

            .link {
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                color: $darkblue;
                transition: color 0.35s ease;

                &:hover {
                    color: $skyblue;
                }

                &.unactive {
                    color: $skyblue;
                }
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .AccountCreated {
        display: grid;
        grid-template-columns: 160px 1fr 2fr;
        min-height: 100vh;

        .content-container {
            padding: 32px 24px;

            .account-created-wrapper {
                padding: 40px;

                h2 {
                    font-size: 40px;
                }
            }
        }
    }
}

@media screen and (min-width: 1025px) {
    .AccountCreated {
        display: grid;
        grid-template-columns: 204px 369px auto;

        .content-container {
            padding: 32px 48px;

            .account-created-wrapper {
                padding: 40px;

                h2 {
                    font-size: 40px;
                }
            }
        }
    }
}