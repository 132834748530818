@import "../../App.scss";

.LeftUserSidebarLink {
    display: flex;
    margin-bottom: 8px;
    padding: 16px 14px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.35s ease background-color;
    text-decoration: none;


    &:hover {
        background-color: #D3DBE0;
    }

    &.active {
        background-color: #D3DBE0;
    }

    .image-container {
        margin-right: 16px;

        img {
            height: 24px;
            width: 24px;
            display: inline-block;
        }
    }

    .info-container {
        .title {
            font-size: 16px;
            font-family: $inter;
            color: #1C2E3B;
            font-weight: 600;
            margin-bottom: 4px;
            display: block;
        }

        .description {
            font-family: $inter;
            display: block;
            font-size: 12px;
            color: #455966;
            line-height: 16px;
        }
    }

    .arrow-container {
        margin-left: auto;
        padding-left: 22px;
    }
}