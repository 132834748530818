@import '../../App.scss';

.ContentLightBlueWrapper {
	background:$lightblue;
	padding: 16px;
    
	h1 {
		font-family: $helveticalight;
		font-size: 32px;
		color: #1C2E3B;
		letter-spacing: 1.6px;
		line-height: 44px;
	}
	img {
		max-width:100%;
	}
	.row {
		background:#fff;
		padding:40px 24px;
	}
}

@media screen and (min-width: 900px) {
    .ContentLightBlueWrapper {
        padding: 40px 32px;
        height: 100vh;
        overflow-y: auto;
    }
}