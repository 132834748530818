@import '../../App.scss';

.FooterLinks {
	text-align: center;

	a {
		font-family: $inter;
		font-weight:bold;
		font-size: 10px;
		color: #1C2E3B;
		letter-spacing: 0;
		text-align: center;
		line-height: 20px;
		text-decoration: none;
        display: inline-block;
        cursor: pointer;
        transition: color 0.35s ease;

        &:hover {
            color: #BCD2E0;
        }
	}

    .footer-pp {
        margin-bottom: 24px;
    }

	div p {
		font-family: $inter;
		font-size: 10px;
		color: #5D6D78;
		letter-spacing: 0;
		text-align: center;
		line-height: 28px;
        margin: 0;
	}
}
