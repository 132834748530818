@import "../../App.scss";

.ProfileBackSection {
    background-color: #EBEEF0;
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back-arrow {
        display: flex;
        align-items: center;
        text-decoration: none;

        img {
            margin-right: 8px;
        }

        div {
            color: $darkblue;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            font-family: $inter;
        }
    }
}