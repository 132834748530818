.LeftUserRegisterSidebar {
    background-color: #EBEEF0;
    height: auto;
    position: relative;
    padding: 32px 16px 18px;
    display: flex;
    flex-direction: column;
    
    .upload-photo-container {
        margin-bottom: 32px;
    }

    h1 {
        font-size: 24px;
        font-weight: 200;
        color: #1C2E3B;
        letter-spacing: 1.2px;
        line-height: 32px;
        margin: 0;
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        color: #576F80;
        line-height: 24px;
        margin-bottom: auto;
    }

    .LeftSideBarPrivacyNav {
        margin-bottom: 16px;
    }

}

@media screen and (min-width: 481px) {
    .LeftUserRegisterSidebar {
        height: 100vh;
        padding: 166px 32px 18px;

    }
}