@import '../../App.scss';

.CreateAccount {
	display: grid;
    grid-template-columns: auto;

    .create-account-content-container {
        padding: 42px 16px 32px;
        display: flex;
        flex-direction: column;
        height: auto;
        min-height: 100vh;
        background-color: $white;

        .UserRegisterForm {
            margin-bottom: 12px;
        }

        .required-fields {
            margin-top: auto;
        }
    }
}

@media screen and (min-width: 900px) {
    .CreateAccount {
        grid-template-columns: 160px 1fr 2fr;

        .create-account-content-container {
            padding: 48px 24px 20px;
            height: 100vh;
            overflow-y: scroll;
        }
    }
}

@media screen and (min-width: 1025px) {
    .CreateAccount {
        grid-template-columns: 204px 369px auto;

        .create-account-content-container {
            padding: 74px 48px 20px;
            height: 100vh;
            overflow-y: scroll;
        }
    }
}